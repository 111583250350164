<template>
  <b-card :title="$t('Siparişlerim')">
    <b-table
      responsive="sm"
      :fields="fields"
      :items="orders"
      striped
    >
      <template #cell(created)="data">
        <div>{{ moment(data.item.created).format('LL') }}</div>
      </template>
      <template #cell(id)="data">
        <div class="text-primary">
          IN4S#{{ data.item.id }}
        </div>
      </template>
      <template #cell(order_status)="data">
        <div
          class="font-weight-bolder"
          :class="data.item.id_order_statuses === '1'? 'text-danger' : ''"
        >
          {{ data.item.order_status }}
        </div>
      </template>
      <template #cell(control)="data">
        <b-button
          :to="'/siparislerim/detay/' + data.item.id"
          variant="outline-primary"
          size="sm"
        >
          Görüntüle
        </b-button>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import { BCard, BTable, BButton } from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BButton,
  },
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: 'SİPARİŞ NO',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
        },
        {
          key: 'created',
          label: 'SİPARİŞ TARİHİ',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
        },
        {
          key: 'order_status',
          label: 'DURUM',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
        },
        {
          key: 'control',
          label: 'Kontrol',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
        },
      ],
    }
  },
  computed: {
    orders() {
      return this.$store.getters['appOrders/getOrders']
    },
  },
  created() {
    this.getCart()
    this.getOrders()
  },
  methods: {
    getOrders() {
      this.$store.dispatch('appOrders/ordersData')
    },
    getCart() {
      this.$store.dispatch('cart/cartData')
    },
  },
}
</script>

<style scoped>

</style>
